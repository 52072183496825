.amplify-button--primary {
    margin-top : 10px;
    background-color : #1d2089;
    font-weight : 400;
}
.amplify-button--primary:hover {
    background-color : #262ab3;
}

div[data-amplify-authenticator] > div[data-amplify-container] {
    display : flex;
    flex-direction : column;
    align-items : center;
    width : 100%;
}

div[data-amplify-router] {
    width : 520px;
}
div[data-amplify-router-content] form[data-amplify-form], 
div[data-amplify-router] form[data-amplify-form] {
    padding-left : 56px;
    padding-right : 56px;
    padding-bottom : 20px;
}

@media (max-width : 30rem) {
    div[data-amplify-router] {
        width : 350px;
    }
    div[data-amplify-router-content] form[data-amplify-form],
    div[data-amplify-router] form[data-amplify-form] {
        padding-left : 16px;
        padding-right : 16px;
    }
}

.amplify-button--link {
    color : #428bf9;
}
.amplify-button--link:hover {
    background-color : #fff;
    color : #428bf9;
    text-decoration : underline;
}
